import { OptionItem } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Checkbox, HStack, Stack, Tag, TagLabel } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MdChevronLeft as BackIcon, MdChevronRight as NextIcon } from 'react-icons/md';
import { HashArgs } from '.';

type Props = {
  rootOptions: OptionItem[];
  parentOptionsMap: HashArgs;
  onCheck: ({
    targetOption,
    isCheckChildren,
  }: {
    targetOption: OptionItem;
    isCheckChildren?: boolean;
  }) => void;
};

const MultipleLayerCheckboxOption: FC<Props> = (props: Props) => {
  const { rootOptions, onCheck, parentOptionsMap } = props;

  const { t } = useTranslation();

  const [options, setOptions] = useState<OptionItem[]>([]);
  const [layer, setLayer] = useState<OptionItem | null>(null);

  const isAllChecked = useCallback((option: OptionItem) => {
    if (option.children && option.children.length > 0)
      return option.children.every((option) => option.isChecked);
    return option.isChecked;
  }, []);

  const isSomeChecked = useCallback((option: OptionItem) => {
    return option.children && option.children.some((option) => option.isChecked);
  }, []);

  // This recursively find some of child option is checked
  const isDeepIndeterminate = useCallback((options: OptionItem[]): boolean | undefined => {
    return options.some((option) => {
      if (option.isChecked) return true;

      if (option.children && option.children.length > 0)
        return isDeepIndeterminate(option.children);

      return;
    });
  }, []);

  const isIndeterminate = useCallback(
    (option: OptionItem) => {
      if (isAllChecked(option)) {
        return !option.isChecked;
      }
      if (option.children && option.children.length > 0) {
        const isSomeChildrenChecked = option.children.some((option) => option.isChecked);
        if (isSomeChildrenChecked) return true;
        return isDeepIndeterminate(option.children);
      }
      return;
    },
    [isDeepIndeterminate, isAllChecked]
  );

  useEffect(() => {
    if (!layer) {
      setLayer(null);
      setOptions(rootOptions);
    } else {
      const previousLayer = parentOptionsMap[layer.id];

      let updatedLayer = null;
      if (!previousLayer) {
        if (rootOptions) {
          updatedLayer = rootOptions.find((option) => option.id === layer.id);
        }
      } else if (previousLayer.children && previousLayer.children.length > 0) {
        updatedLayer = previousLayer.children.find((option) => option.id === layer.id);
      }

      if (updatedLayer) {
        setLayer(updatedLayer);
        setOptions(updatedLayer.children || []);
      }
    }
  }, [layer, parentOptionsMap, rootOptions]);

  const handleBack = () => {
    if (layer) {
      const previousLayer = parentOptionsMap[layer.id];
      if (previousLayer && previousLayer.children) {
        setLayer(previousLayer);
        setOptions(previousLayer.children);
        return;
      }
    }
    resetLayer();
  };

  const handleSelect = (option: OptionItem) => {
    if (option.children && option.children.length > 0) {
      setLayer(option);
      setOptions(option.children);
    }
  };

  const resetLayer = () => {
    setLayer(null);
    setOptions(rootOptions);
  };

  const deepCountCheckedOptions = (option: OptionItem) => {
    let checkedCount = option.isChecked ? 1 : 0;
    if (option.children && option.children.length > 0) {
      option.children.forEach((childOption) => {
        checkedCount = checkedCount + deepCountCheckedOptions(childOption);
      });
    }

    return checkedCount;
  };

  const isLayerChecked = useMemo(() => {
    if (layer) {
      return isAllChecked(layer) && layer.isChecked;
    }
    return false;
  }, [isAllChecked, layer]);

  const onCheckClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, option: OptionItem) => {
    e.preventDefault();
    if (option.children && option.children.length > 0) {
      handleSelect(option);
      return;
    }
    onCheck({ targetOption: option, isCheckChildren: true });
  };

  return (
    <>
      {layer && (
        <Stack ml={0} mb={1} spacing='0'>
          <Button
            variant='ghost'
            pl={1}
            colorScheme='neutral'
            color='neutral.500'
            fontWeight='500'
            justifyContent='start'
            leftIcon={<BackIcon color='neutral.500' />}
            onClick={() => handleBack()}
          >
            {t('actions.back')}
          </Button>

          {!layer.isSelectDisabled && (
            <>
              <HStack bg={isLayerChecked ? 'primary.100' : 'transparent'} spacing={0}>
                <Checkbox
                  pl={2}
                  w='100%'
                  fontWeight='500'
                  colorScheme='primary'
                  color={isLayerChecked ? 'primary.600' : ''}
                  bg={isLayerChecked ? 'primary.100' : ''}
                  _hover={{ backgroundColor: isLayerChecked ? 'primary.100' : 'neutral.50' }}
                  height='40px'
                  isChecked={isLayerChecked}
                  isIndeterminate={isIndeterminate(layer)}
                  onChange={() =>
                    onCheck({
                      targetOption: {
                        ...layer,
                        isChecked: isLayerChecked,
                      },
                      isCheckChildren: true,
                    })
                  }
                >
                  {t('all')}
                </Checkbox>
              </HStack>
              <HStack bg={layer.isChecked ? 'primary.100' : 'transparent'} spacing={0}>
                <Checkbox
                  pl={2}
                  w='100%'
                  fontWeight='500'
                  colorScheme='primary'
                  color={layer.isChecked ? 'primary.600' : ''}
                  bg={layer.isChecked ? 'primary.100' : ''}
                  _hover={{ backgroundColor: layer.isChecked ? 'primary.100' : 'neutral.50' }}
                  height='40px'
                  isChecked={layer.isChecked}
                  onChange={() => onCheck({ targetOption: layer })}
                >
                  {`${layer.label}（${t('parent-item')})`}
                </Checkbox>
              </HStack>
            </>
          )}
        </Stack>
      )}
      <Stack
        spacing={0}
        sx={{
          pl: layer ? '1rem' : 0,
        }}
      >
        {options.length > 0 &&
          options
            .filter(
              (option) =>
                !option.isSelectDisabled || (option.children && option.children.length > 0)
            ) // 選択できないオプションで、子要素が存在しない場合は表示しない
            .map((option) => {
              const isOptionAllChildrenChecked = isAllChecked(option);
              const isOptionSomeChildrenChecked = isSomeChecked(option);
              const isOptionIndeterminate = isIndeterminate(option);

              return (
                <HStack
                  key={option.id}
                  bg={option.isChecked ? 'primary.100' : 'transparent'}
                  spacing='1'
                  _hover={{ backgroundColor: option.isChecked ? 'primary.100' : 'neutral.50' }}
                  onClick={(e) => onCheckClick(e, option)}
                >
                  <Checkbox
                    w='100%'
                    h='40px'
                    fontWeight='500'
                    colorScheme='primary'
                    color={option.isChecked ? 'primary.600' : ''}
                    pl={2}
                    isChecked={isOptionAllChildrenChecked}
                    isIndeterminate={
                      isOptionIndeterminate || (option.isChecked && !isOptionAllChildrenChecked)
                    }
                  >
                    {option.label}
                  </Checkbox>
                  {option.children && option.children.length > 0 && (
                    <>
                      {(isOptionIndeterminate ||
                        isOptionSomeChildrenChecked ||
                        option.isChecked) && (
                        <Tag p={0} bg='primary.600' rounded='full' size='sm'>
                          <TagLabel color='neutral.0' w='full' textAlign='center'>
                            {deepCountCheckedOptions(option)}
                          </TagLabel>
                        </Tag>
                      )}
                      <NextIcon />
                    </>
                  )}
                </HStack>
              );
            })}
      </Stack>
    </>
  );
};

export default MultipleLayerCheckboxOption;
